<script>


    import {AuthClient} from "@dfinity/auth-client";
    import {onMount} from "svelte";
    import {
        auth, createActor, plugWallet, whitelist, host,
        VAULT_CANISTER_ID, LEDGER_CANISTER_ID
    } from "../store/auth";
    import {idlFactory as ckbtcActorIDL} from "../../declarations/icr1/ckbtc.did.js";
    import {idlFactory as ledgerActorIDL} from "../../declarations/ledger/ledger.did.js";
    import {idlFactory as vaultActorIDL} from "../../declarations/vault/vault.did.js";
    import {HttpAgent} from "@dfinity/agent/lib/cjs/agent";
    import {canisters, createCanisterActor, userBalances} from "../store/store";
    import {Principal} from "@dfinity/principal";
    import {showNotice,} from '@brewer/beerui'
    import {toHexString, hexToBytes, principalToAccountDefaultIdentifier} from '../utils/helpers'
    import {actorList,iiPrincipal} from "../store/wallet";

    import BigNumber from "bignumber.js";

    let activeIndex = 0;
    let activeCoin = "ckBTC";
    let btcBalance = 0, ethBalance = 0, btcAllowance, ethAllowance, myBTCPosition = 0, myETHPosition = 0;
    let ethDecimals = 8, btcDecimals = 18;
    let stakeAmount, withdrawAmount;
    /** @type {AuthClient} */


    let isConnected;
    let iiPrincipalString = '';
    let principal;

    // Actors for corresponding canisters

    // TDOD: Move to a store
    let vaultActor, ckbtcActor, ckethActor, ledgerActor;
    let poolBalance = 0;

    // Plug wallet connection request
    onMount(async () => {
        iiPrincipal.subscribe((value)=>{
            if(value){
                iiPrincipalString = Principal.fromText(value.toString());
               principal = iiPrincipalString

            }
        })
        actorList.subscribe((value)=>{
            // console.log("isConneted",value)
            if(value.isGetActor){
                vaultActor = value.vaultActor;
                ckbtcActor = value.ckbtcActor;
                ckethActor = value.ckethActor;
                ledgerActor = value.ledgerActor;
                isConnected = true
                getData()
            }
        })



    });
    const getAllowance = async () => {
        console.log({
            account: principal,
            spender: Principal.fromText(process.env.VAULT_CANISTER_ID)
        })
        try {
            const res = await ckbtcActor.icrc2_allowance({
                account: {
                    owner: principal,
                    subaccount: []
                },
                spender: {
                    owner: Principal.fromText(process.env.VAULT_CANISTER_ID),
                    subaccount: []
                }
            })
            btcAllowance = res.allowance

            const ethres = await ckethActor.icrc2_allowance({
                account: {
                    owner: principal,
                    subaccount: []
                },
                spender: {
                    owner: Principal.fromText(process.env.VAULT_CANISTER_ID),
                    subaccount: []
                }
            })
            ethAllowance = ethres.allowance
        } catch (e) {
            console.error(e)
        }
    }

    const handleApprove = async () => {

        const res = await ckbtcActor.icrc2_approve({
            amount: 10_000_000_000,
            created_at_time: [],
            expected_allowance: [],
            expires_at: [],
            fee: [],
            from_subaccount: [],
            memo: [],
            spender: {
                owner: Principal.fromText(process.env.VAULT_CANISTER_ID),
                subaccount: []
            }
        })
        console.log({
            approveRes: res
        })
        return res

    }
    const getData = async () => {
        getAllowance()
        // get decimal
        try {
            const decimal = await ckbtcActor.icrc1_decimals()
            btcDecimals = decimal
            const decimal2 = await ckethActor.icrc1_decimals()
            ethDecimals = decimal2
        } catch (e) {
            console.error(e)
        }
        // get btc balance
        try {
            const res = await ckbtcActor.icrc1_balance_of({owner: principal, subaccount: []})
            btcBalance = BigNumber(res).div(BigNumber(10).pow(btcDecimals))
        } catch (e) {
            console.error(e)
        }
        // get ethBalance
        try {
            const res = await ckethActor.icrc1_balance_of({owner: principal, subaccount: []})
            ethBalance = BigNumber(res).div(BigNumber(10).pow(ethDecimals))
        } catch (e) {
            console.error(e)
        }
        // try {
        //     const res = await ledgerActor.account_balance({account: hexToBytes(principalToAccountDefaultIdentifier(iiPrincipalString))});
        //     console.log(res)
        // } catch (e) {
        //     console.error(e)
        // }
        try {
            const balanceRes = await vaultActor.balances(principal)

            if (balanceRes) {
                for (let i = 0; i < balanceRes.length; i++) {
                    if (process.env.CKBTC_CANISTER_ID == balanceRes[i][0].toString()) {
                        myBTCPosition = BigNumber(balanceRes[i][1].balance).div(BigNumber(10).pow(btcDecimals))
                    }
                    if (process.env.CKETH_CANISTER_ID == balanceRes[i][0].toString()) {
                        myETHPosition = BigNumber(balanceRes[i][1].balance).div(BigNumber(10).pow(ethDecimals))
                    }
                }
            }

        } catch (e) {
            console.error(e)
        }
    }
    const handleWithdraw = async () => {
        let principal, decimals
        if (activeCoin == "ckBTC") {
            principal = Principal.fromText(process.env.CKBTC_CANISTER_ID);
            decimals = btcDecimals
        } else {
            principal = Principal.fromText(process.env.CKETH_CANISTER_ID);
            decimals = ethDecimals
        }

        if (!$auth.loggedIn) {
            showNotice({
                type: "warning",
                title: 'Staking Failed',
                message: 'Please login'
            })
            return
        }
        try {
            const res = await vaultActor.withdraw(principal, parseInt(BigNumber(withdrawAmount).multipliedBy(BigNumber(10).pow(decimals))))
            if (res.Ok) {
                getData()
                //
                showNotice({
                    type: "success",
                    title: 'Withdraw success!',
                    message: 'Withdraw success!'
                })
            } else {
                showNotice({
                    type: "warning",
                    title: 'Withdraw error',
                    message: 'Withdraw error'
                })
            }
        } catch (e) {
            console.log(e)
            showNotice({
                type: "warning",
                title: 'Withdraw error',
                message: 'Withdraw error'
            })
        }
    }
    const handleStake = async () => {

        if (!$auth.loggedIn) {
            showNotice({
                type: "warning",
                title: 'Staking Failed',
                message: 'Please login'
            })
            return
        }
        let principal, decimals
        if (activeCoin == "ckBTC") {
            principal = Principal.fromText(process.env.CKBTC_CANISTER_ID);
            decimals = btcDecimals
        } else {
            principal = Principal.fromText(process.env.CKETH_CANISTER_ID);
            decimals = ethDecimals
        }

        let amount = BigNumber(stakeAmount).multipliedBy(BigNumber(10).pow(decimals))
        if (btcAllowance < amount) {
            await handleApprove()
        }
        try {
            const res = await vaultActor.deposit(principal, parseInt(amount))
            if (res.Ok) {
                getData()
                //
                showNotice({
                    type: "success",
                    title: 'Staking success!',
                    message: 'Staking success!'
                })
            } else {
                showNotice({
                    type: "warning",
                    title: 'Staking error',
                    message: 'Staking error'
                })
            }
        } catch (e) {
            console.log(e)
            showNotice({
                type: "warning",
                title: 'Staking error',
                message: 'Staking error'
            })
        }

    }
    const setMax = () => {
        if (activeCoin == "ckBTC") {
            stakeAmount = btcBalance.toString()
        }
        if (activeCoin == "ckETH") {
            stakeAmount = ethBalance
        }
    }

</script>
<div class="onBuilding">
    This module is not yet available. Please stay tuned for updates.
</div>
<!--<div class="staking">-->
<!--    <div class="coin-select">-->
<!--        <div class="coin-select-content">-->
<!--            <div class="select-item {activeCoin=='ckBTC'?'active':''}" on:click={()=>{activeCoin='ckBTC'}}>-->
<!--                ckBTC-->
<!--            </div>-->
<!--            <div class="select-item {activeCoin=='ckETH'?'active':''}" on:click={()=>{activeCoin='ckETH'}}>-->
<!--                ckETH-->
<!--            </div>-->
<!--        </div>-->
<!--    </div>-->
<!--    <div class="select-list">-->
<!--        <div class="select-item {activeIndex==0?'active':''}" on:click={()=>{activeIndex=0}}>-->
<!--            Stake-->
<!--        </div>-->
<!--        <div class="select-item {activeIndex==1?'active':''}" on:click={()=>{activeIndex=1}}>-->
<!--            Position-->
<!--        </div>-->
<!--    </div>-->
<!--    {#if activeIndex == 0}-->
<!--        <div class="header-list" >-->
<!--            <div class="list-item">-->
<!--                <div class="item-name">-->
<!--                    7-Day-->
<!--                </div>-->
<!--                <div class="detail">-->
<!--                    <div class="in-line">-->
<!--                        <div class="name">-->
<!--                            APY-->
<!--                        </div>-->
<!--                        <div class="value">-->
<!--                            12.11%-->
<!--                        </div>-->
<!--                    </div>-->
<!--                </div>-->
<!--            </div>-->
<!--            <div class="list-item">-->
<!--                <div class="item-name">-->
<!--                    7-Day-->
<!--                </div>-->
<!--                <div class="detail">-->
<!--                    <div class="in-line">-->
<!--                        <div class="name">-->
<!--                            APY-->
<!--                        </div>-->
<!--                        <div class="value">-->
<!--                            12.11%-->
<!--                        </div>-->
<!--                    </div>-->
<!--                </div>-->
<!--            </div>-->
<!--            <div class="list-item">-->
<!--                <div class="item-name">-->
<!--                    7-Day-->
<!--                </div>-->
<!--                <div class="detail">-->
<!--                    <div class="in-line">-->
<!--                        <div class="name">-->
<!--                            APY-->
<!--                        </div>-->
<!--                        <div class="value">-->
<!--                            12.11%-->
<!--                        </div>-->
<!--                    </div>-->
<!--                </div>-->
<!--            </div>-->
<!--            <div class="list-item">-->
<!--                <div class="item-name">-->
<!--                    7-Day-->
<!--                </div>-->
<!--                <div class="detail">-->
<!--                    <div class="in-line">-->
<!--                        <div class="name">-->
<!--                            APY-->
<!--                        </div>-->
<!--                        <div class="value">-->
<!--                            12.11%-->
<!--                        </div>-->
<!--                    </div>-->
<!--                </div>-->
<!--            </div>-->
<!--        </div>-->
<!--        <div class="staking-box">-->
<!--            <div class="staking-box-title">-->
<!--                Stake-->
<!--            </div>-->
<!--            <div class="input-box">-->
<!--                <input type="text" bind:value={stakeAmount} placeholder="0">-->
<!--                <div class="input-tip">-->
<!--                    <div class="mint-btn" on:click={()=>{setMax()}}>-->
<!--                        MAX-->
<!--                    </div>-->
<!--                </div>-->
<!--            </div>-->

<!--            <div class="balance-box">-->
<!--                <div class="balance-box-name">-->
<!--                    Balance-->
<!--                </div>-->
<!--                <div class="balance-box-value">-->
<!--                    {btcBalance.toString()} {activeCoin}-->
<!--                </div>-->
<!--            </div>-->
<!--            <div class="mint-btn" on:click={()=>{handleStake()}}>-->
<!--                Mint-->
<!--            </div>-->

<!--&lt;!&ndash;            <div class="balance-box">&ndash;&gt;-->
<!--&lt;!&ndash;                <div class="balance-box-name">&ndash;&gt;-->
<!--&lt;!&ndash;                    Maturity Date(UTC)&ndash;&gt;-->
<!--&lt;!&ndash;                </div>&ndash;&gt;-->
<!--&lt;!&ndash;                <div class="balance-box-value">&ndash;&gt;-->
<!--&lt;!&ndash;                    Mar 26,2024&ndash;&gt;-->
<!--&lt;!&ndash;                </div>&ndash;&gt;-->
<!--&lt;!&ndash;            </div>&ndash;&gt;-->
<!--        </div>-->
<!--    {:else}-->
<!--        <div class="position-info">-->
<!--            <div class="info-item">-->
<!--                <div class="name">-->
<!--                    Balance-->
<!--                </div>-->
<!--                <div class="value">-->
<!--                    {activeCoin=="ckBTC"?myBTCPosition:myETHPosition} {activeCoin}-->
<!--                </div>-->
<!--            </div>-->
<!--            <input type="text" bind:value={withdrawAmount} placeholder="0">-->
<!--            <div class="mint-btn" on:click={()=>{handleWithdraw()}}>-->
<!--                Withdraw-->
<!--            </div>-->
<!--            &lt;!&ndash;            <div class="info-item">&ndash;&gt;-->
<!--            &lt;!&ndash;                <div class="name">&ndash;&gt;-->
<!--            &lt;!&ndash;                    Accumulated Rewards&ndash;&gt;-->
<!--            &lt;!&ndash;                </div>&ndash;&gt;-->
<!--            &lt;!&ndash;                <div class="value">&ndash;&gt;-->
<!--            &lt;!&ndash;                    549,485.5998&ndash;&gt;-->
<!--            &lt;!&ndash;                </div>&ndash;&gt;-->
<!--            &lt;!&ndash;            </div>&ndash;&gt;-->
<!--            &lt;!&ndash;            <div class="info-item">&ndash;&gt;-->
<!--            &lt;!&ndash;                <div class="name">&ndash;&gt;-->
<!--            &lt;!&ndash;                    Latest Rewards&ndash;&gt;-->
<!--            &lt;!&ndash;                </div>&ndash;&gt;-->
<!--            &lt;!&ndash;                <div class="value">&ndash;&gt;-->
<!--            &lt;!&ndash;                    85.54&ndash;&gt;-->
<!--            &lt;!&ndash;                </div>&ndash;&gt;-->
<!--            &lt;!&ndash;            </div>&ndash;&gt;-->
<!--        </div>-->
<!--        <div class="staked-box" style="display: none">-->
<!--            <div class="title">-->
<!--                Staked(3)-->
<!--            </div>-->
<!--            <div class="stake-content">-->

<!--            </div>-->
<!--        </div>-->
<!--    {/if}-->
<!--</div>-->

<style lang="scss">
  .coin-select {
    width: 1100px;
    margin: 0 auto;
    border-bottom: 1px solid #EAEAEA;
    margin-bottom: 50px;
    display: flex;
    justify-content: center;

    .coin-select-content {
      display: flex;
      justify-content: space-between;
      width: 400px;

      .select-item {
        width: 50%;
        height: 50px;
        text-align: center;
        font-weight: bold;
        cursor: pointer;
        font-size: var(--weightFont);
        color: #282626;

        &.active {
          border-bottom: 1px solid #623DE7;
          color: #623CE7;
        }
      }
    }
  }

  .staked-box {
    width: 556px;
    background: #FEFEFF;
    box-shadow: 0px 2px 3px 0px rgba(41, 72, 152, 0.01), 0px 9px 7px 0px rgba(41, 72, 152, 0.02), 0px 22px 14px 0px rgba(41, 72, 152, 0.03), 0px 42px 28px 0px rgba(41, 72, 152, 0.03), 0px 71px 51px 0px rgba(41, 72, 152, 0.04), 0px 109px 87px 0px rgba(41, 72, 152, 0.05);
    border-radius: 11px 11px 11px 11px;
    padding: 30px;
    margin: 30px auto;

    .title {
      font-family: Roboto, Roboto;
      font-weight: bold;
      font-size: var(--bigFont);
      color: #000000;
    }
  }

  .position-info {
    width: 556px;
    background: #FEFEFF;
    box-shadow: 0px 2px 3px 0px rgba(41, 72, 152, 0.01), 0px 9px 7px 0px rgba(41, 72, 152, 0.02), 0px 22px 14px 0px rgba(41, 72, 152, 0.03), 0px 42px 28px 0px rgba(41, 72, 152, 0.03), 0px 71px 51px 0px rgba(41, 72, 152, 0.04), 0px 109px 87px 0px rgba(41, 72, 152, 0.05);
    border-radius: 11px 11px 11px 11px;
    margin: 0 auto;
    padding: 30px;

    .info-item {
      display: flex;
      justify-content: space-between;
      margin-top: var(--weightFont);

      &:first-child {
        margin-top: 0;
      }

      .name {
        font-weight: 500;
        font-size: var(--weightFont);
        color: #929292;
      }

      .value {
        font-weight: bold;
        font-size: var(--weightFont);
        color: #282626;
      }
    }
  }

  .staking {
    width: 1200px;
    margin: 0 auto;

    .input-tip {
      .mint-btn {
        width: 100px;
        height: 40px;
        line-height: 40px;
        margin-top: -10px;
        margin-right: -var(--weightFont);
        border-radius: 0 10px 10px 10px;
      }
    }

    .select-list {
      display: flex;
      width: 400px;
      margin: var(--weightFont) auto 30px;
      justify-content: space-between;

      .select-item {
        width: 190px;
        height: 50px;
        background: #FFFFFF;
        cursor: pointer;
        box-shadow: 0px 2px 3px 0px rgba(0, 0, 0, 0.01), 0px 8px 7px 0px rgba(0, 0, 0, 0.01), 0px var(--weightFont) 13px 0px rgba(0, 0, 0, 0.01), 0px 39px 25px 0px rgba(0, 0, 0, 0.02), 0px 65px 47px 0px rgba(0, 0, 0, 0.02), 0px 100px 80px 0px rgba(0, 0, 0, 0.03);
        border-radius: 10px 10px 10px 10px;
        border: 1px solid #F3F2F2;
        line-height: 50px;
        text-align: center;
        font-weight: bold;
        font-size: var(--weightFont);
        color: #282626;

        &.active {
          background: #623CE7;
          color: #fff;
        }
      }
    }

    .header-list {
      display: flex;
      width: 100%;
      justify-content: space-between;

      .list-item {
        cursor: pointer;
        min-width: 22%;
        display: block;

        background: #FEFEFF;
        box-shadow: 0px 2px 3px 0px rgba(41, 72, 152, 0.01), 0px 9px 7px 0px rgba(41, 72, 152, 0.02), 0px 22px 14px 0px rgba(41, 72, 152, 0.03), 0px 42px 28px 0px rgba(41, 72, 152, 0.03), 0px 71px 51px 0px rgba(41, 72, 152, 0.04), 0px 109px 87px 0px rgba(41, 72, 152, 0.05);
        border-radius: 11px 11px 11px 11px;
        padding: 30px;

        .item-name {
          font-family: Roboto, Roboto;
          font-weight: bold;
          font-size: var(--bigFont);
          color: #282626;
        }

        .detail {
          .in-line {
            display: flex;
            justify-content: space-between;
            margin-top: 25px;

            .name {
              font-size: var(--weightFont);
              color: #999999;
            }

            .value {
              font-weight: bold;
              font-size: var(--weightFont);
              color: #333333;
            }
          }
        }
      }
    }

    .staking-box {
      width: 556px;
      position: relative;
      z-index: 1;
      background: #FEFEFF;
      box-shadow: 0px 2px 3px 0px rgba(41, 72, 152, 0.01), 0px 9px 7px 0px rgba(41, 72, 152, 0.02), 0px 22px 14px 0px rgba(41, 72, 152, 0.03), 0px 42px 28px 0px rgba(41, 72, 152, 0.03), 0px 71px 51px 0px rgba(41, 72, 152, 0.04), 0px 109px 87px 0px rgba(41, 72, 152, 0.05);
      border-radius: 11px 11px 11px 11px;
      padding: 30px;
      margin: 50px auto;

      .staking-box-title {
        font-weight: bold;
        font-size: 30px;
        color: #000000;
      }

      .balance-box {
        display: flex;
        margin-top: var(--weightFont);
        justify-content: space-between;

        .balance-box-name {
          font-weight: 500;
          font-size: var(--weightFont);
          color: #929292;
        }

        .balance-box-value {
          font-weight: bold;
          font-size: var(--weightFont);
          color: #282626;
        }
      }
    }


  }

  @media screen and (max-width: 800px) {
    .staking {
      width: 100%;
      padding: 0 10px;

      .coin-select {
        width: 100%;

        .coin-select-content {
          width: 100%;
        }
      }

      .select-list {
        width: 100%;

        .select-item {
          width: 48%;
        }
      }

      .header-list {
        flex-direction: column;

        .list-item {
          margin-top: 30px;

          .item-name {
            font-size: var(--weightFont);
          }

          .detail {
            .in-line {
              .name {
                font-size: 18px;
              }

              .value {
                font-size: var(--weightFont);
              }
            }
          }

          &:first-child {
            margin-top: 0;
          }
        }
      }

      .staking-box {
        width: 100%;

        .staking-box-title {
          font-size: 26px;
        }
      }

      .position-info {
        width: 100%;

        .info-item {
          .name {
            font-size: 16px;
          }

          .value {
            font-size: 18px;
          }
        }

      }

      .staked-box {
        width: 100%;

        .title {
          font-size: 22px;
        }
      }
    }


  }
</style>
