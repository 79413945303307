export const idlFactory = ({ IDL }) => {
  const InitArgs = IDL.Record({
    'vault' : IDL.Principal,
    'cusd' : IDL.Principal,
  });
  const Error = IDL.Variant({
    'InsufficientAllowance' : IDL.Null,
    'ZeroAddress' : IDL.Null,
    'AmountLessThanFee' : IDL.Null,
    'TransferFailure' : IDL.Null,
    'OnlyVault' : IDL.Null,
    'InsufficientBalance' : IDL.Null,
    'InvalidFee' : IDL.Null,
    'Exists' : IDL.Null,
  });
  const Result = IDL.Variant({ 'Ok' : IDL.Null, 'Err' : Error });
  const AssetInfo = IDL.Record({
    'asset_add' : IDL.Nat,
    'wait_withdrawal' : IDL.Nat,
    'cusd_sub' : IDL.Nat,
  });
  const UserInfo = IDL.Record({
    'underlyings' : IDL.Vec(IDL.Tuple(IDL.Principal, AssetInfo)),
    'balance' : IDL.Nat,
    'total_add' : IDL.Nat,
    'total_sub' : IDL.Nat,
  });
  const State = IDL.Record({
    'fee' : IDL.Nat,
    'balance' : IDL.Nat,
    'need_fee' : IDL.Nat,
    'vault' : IDL.Opt(IDL.Principal),
    'cusd' : IDL.Opt(IDL.Principal),
    'total_sub' : IDL.Nat,
    'custodians' : IDL.Vec(IDL.Principal),
    'users' : IDL.Vec(IDL.Tuple(IDL.Principal, UserInfo)),
    'debt_underlying' : IDL.Vec(IDL.Tuple(IDL.Principal, IDL.Nat)),
  });
  return IDL.Service({
    'debetUnderlying' : IDL.Func([IDL.Principal], [IDL.Nat], ['query']),
    'deposit' : IDL.Func([IDL.Nat], [Result], []),
    'fixBalance' : IDL.Func([IDL.Nat], [Result], []),
    'getState' : IDL.Func([], [State], ['query']),
    'liquidate' : IDL.Func([IDL.Nat, IDL.Nat, IDL.Principal], [IDL.Nat], []),
    'setCUSD' : IDL.Func([IDL.Principal, IDL.Nat], [Result], []),
    'stats' : IDL.Func([], [IDL.Nat, IDL.Nat], ['query']),
    'userBalance' : IDL.Func([IDL.Principal], [IDL.Nat], ['query']),
    'userInfo' : IDL.Func([IDL.Principal], [UserInfo], ['query']),
    'userUnderlyingBalance' : IDL.Func(
        [IDL.Principal, IDL.Principal],
        [IDL.Nat],
        ['query'],
    ),
    'userUnderlyingBalances' : IDL.Func(
        [IDL.Principal],
        [IDL.Vec(IDL.Tuple(IDL.Principal, IDL.Nat))],
        ['query'],
    ),
    'withdraw' : IDL.Func([IDL.Nat], [Result], []),
    'withdrawDebetUnderlying' : IDL.Func(
        [IDL.Principal, IDL.Nat],
        [Result],
        [],
    ),
    'withdrawUnderlying' : IDL.Func([IDL.Principal, IDL.Nat], [Result], []),
  });
};
export const init = ({ IDL }) => {
  const InitArgs = IDL.Record({
    'vault' : IDL.Principal,
    'cusd' : IDL.Principal,
  });
  return [InitArgs];
};
