export const idlFactory = ({ IDL }) => {
  const InitArgs = IDL.Record({ 'timer_interval_secs' : IDL.Nat64 });
  const Error = IDL.Variant({
    'Overflow' : IDL.Null,
    'InsufficientAllowance' : IDL.Null,
    'InsufficientCollateral' : IDL.Null,
    'ZeroAddress' : IDL.Null,
    'AmountLessThanFee' : IDL.Null,
    'TransferFailure' : IDL.Null,
    'InsufficientBalance' : IDL.Null,
    'PriceFormatError' : IDL.Null,
    'BorrowLessThenCost' : IDL.Null,
    'InvalidFee' : IDL.Null,
    'UnderlyingNotEnable' : IDL.Null,
    'Unauthorized' : IDL.Null,
    'InvalidRate' : IDL.Null,
    'NotExists' : IDL.Null,
    'ExceedingLoanAmount' : IDL.Null,
    'UnderlyingNotFound' : IDL.Null,
    'Exists' : IDL.Null,
  });
  const Result = IDL.Variant({ 'Ok' : IDL.Null, 'Err' : Error });
  const UserInfo = IDL.Record({ 'balance' : IDL.Nat, 'borrow' : IDL.Nat });
  const Result_1 = IDL.Variant({ 'Ok' : IDL.Nat, 'Err' : Error });
  const Platform = IDL.Variant({
    'Coinbase' : IDL.Null,
    'Coingecko' : IDL.Null,
  });
  const PriceInfo = IDL.Record({
    'weight' : IDL.Nat,
    'name' : IDL.Text,
    'laste_update_at' : IDL.Nat64,
    'price' : IDL.Nat,
  });
  const UnderlyingInfo = IDL.Record({
    'fee' : IDL.Nat,
    'decimals' : IDL.Nat8,
    'need_fee' : IDL.Nat,
    'cost' : IDL.Nat,
    'name' : IDL.Text,
    'borrow' : IDL.Nat,
    'deposit' : IDL.Nat,
    'prices' : IDL.Vec(IDL.Tuple(Platform, PriceInfo)),
    'fee_balance' : IDL.Nat,
    'liquidate_rate' : IDL.Nat,
    'enable' : IDL.Bool,
  });
  const State = IDL.Record({
    'timer_interval_secs' : IDL.Nat64,
    'withdrawed_cusd_fee' : IDL.Nat,
    'cusd' : IDL.Opt(IDL.Principal),
    'underlying' : IDL.Vec(IDL.Tuple(IDL.Principal, UnderlyingInfo)),
    'custodians' : IDL.Vec(IDL.Principal),
    'reserve_pool' : IDL.Opt(IDL.Principal),
    'fee_balance' : IDL.Nat,
    'excessive_cusd' : IDL.Nat,
    'balances' : IDL.Vec(
      IDL.Tuple(IDL.Principal, IDL.Vec(IDL.Tuple(IDL.Principal, UserInfo)))
    ),
  });
  const HttpHeader = IDL.Record({ 'value' : IDL.Text, 'name' : IDL.Text });
  const HttpResponse = IDL.Record({
    'status' : IDL.Nat,
    'body' : IDL.Vec(IDL.Nat8),
    'headers' : IDL.Vec(HttpHeader),
  });
  const TransformArgs = IDL.Record({
    'context' : IDL.Vec(IDL.Nat8),
    'response' : HttpResponse,
  });
  const Result_2 = IDL.Variant({ 'Ok' : UnderlyingInfo, 'Err' : Error });
  return IDL.Service({
    'addUnderlying' : IDL.Func(
        [IDL.Principal, IDL.Bool, IDL.Nat, IDL.Nat, IDL.Text, IDL.Nat],
        [Result],
        [],
      ),
    'balance' : IDL.Func([IDL.Principal, IDL.Principal], [UserInfo], ['query']),
    'balances' : IDL.Func(
        [IDL.Principal],
        [IDL.Vec(IDL.Tuple(IDL.Principal, UserInfo))],
        ['query'],
      ),
    'borrow' : IDL.Func([IDL.Principal, IDL.Nat], [Result], []),
    'burn' : IDL.Func([IDL.Nat], [Result], []),
    'checkBorrow' : IDL.Func([], [IDL.Nat], []),
    'collateralInspection' : IDL.Func([], [], []),
    'deposit' : IDL.Func([IDL.Principal, IDL.Nat], [Result], []),
    'excessive' : IDL.Func([], [IDL.Nat], ['query']),
    'feeBalance' : IDL.Func([], [IDL.Nat], ['query']),
    'getCustodian' : IDL.Func([], [IDL.Vec(IDL.Principal)], ['query']),
    'getPrice' : IDL.Func([IDL.Principal], [Result_1], ['query']),
    'getPrices' : IDL.Func(
        [],
        [IDL.Vec(IDL.Tuple(IDL.Principal, IDL.Nat))],
        ['query'],
      ),
    'getState' : IDL.Func([], [State], ['query']),
    'isCustodian' : IDL.Func([IDL.Principal], [IDL.Bool], ['query']),
    'maxBorrow' : IDL.Func(
        [IDL.Principal, IDL.Principal],
        [IDL.Nat],
        ['query'],
      ),
    'repayment' : IDL.Func([IDL.Principal, IDL.Nat], [Result], []),
    'setCUSD' : IDL.Func([IDL.Principal], [Result], []),
    'setCustodian' : IDL.Func([IDL.Principal, IDL.Bool], [Result], []),
    'setMockPrice' : IDL.Func([IDL.Principal, Platform, IDL.Nat], [Result], []),
    'setReservePool' : IDL.Func([IDL.Principal], [Result], []),
    'setUnderlyingPlatform' : IDL.Func(
        [IDL.Principal, Platform, IDL.Text, IDL.Nat],
        [Result],
        [],
      ),
    'transform' : IDL.Func([TransformArgs], [HttpResponse], ['query']),
    'underlying' : IDL.Func([IDL.Principal], [Result_2], ['query']),
    'underlyingList' : IDL.Func(
        [],
        [IDL.Vec(IDL.Tuple(IDL.Principal, UnderlyingInfo))],
        ['query'],
      ),
    'updatePrice' : IDL.Func([], [Result], []),
    'withdraw' : IDL.Func([IDL.Principal, IDL.Nat], [Result], []),
    'withdrawCusdFee' : IDL.Func([IDL.Nat], [Result], ['query']),
    'withdrawFee' : IDL.Func([IDL.Nat], [Result], []),
    'withdrawUnderlyingFee' : IDL.Func([IDL.Principal, IDL.Nat], [Result], []),
    'withdrawedCusdFee' : IDL.Func([], [IDL.Nat], ['query']),
  });
};
export const init = ({ IDL }) => {
  const InitArgs = IDL.Record({ 'timer_interval_secs' : IDL.Nat64 });
  return [InitArgs];
};
